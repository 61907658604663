<template>
  <div class="flex flex-col gap-y-2">
    <div id="product-list-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="reminders-adv-filters-1" class="flex flex-wrap items-center gap-3">
            <div class="flex flex-wrap items-center gap-3">
              <!-- Search Button with addons -->
              <div class="flex flex-none items-center">
                <input
                  id="filterInput"
                  class="border-gray-300 dark:border-gray-600 rounded-r-none"
                  :class="{'bg-yellow-100 dark:bg-yellow-800': activeFilters.search.length > 0}"
                  v-model="activeFilters.search"
                  type="text"
                  placeholder="wpisz aby wyszukać..."
                >
                <button
                  @click="activeFilters.search = ''"
                  class="tw--btn tw--btn-secondary rounded-none border-t border-b border-gray-300 dark:border-gray-600"
                  type="button"
                  :disabled="activeFilters.search === ''"
                  title="wyczyść"
                ><FontAwesomeIcon icon="times"/></button>
                <button
                  @click="setUrl"
                  class="tw--btn border border-blue-600 dark:border-blue-900 rounded-l-none"
                  type="button"
                  :disabled="activeFilters.search === ''"
                >
                  Zatwierdź
                </button>
              </div>
              <!-- End Search Button -->

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @input="setUrl"
                v-model="activeFilters.insurer"
                label="TU"
                :options="insurerList"
              />

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @input="setUrl"
                v-model="activeFilters.category"
                label="Kategoria"
                :options="productCategoryList"
              />

            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="initList" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>

      </div>

      <SortList v-if="sortedBy.field !== ''" @sort="sortItemsBy" :fields="sortFields" :selected="sortedBy" class="max-w-min" />

      <div class="flex flex-wrap justify-between gap-y-1 text-sm">
        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-white dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-indigo-500 dark:bg-indigo-900">system prowizyjny</span>
          <span class="px-3 py-1 rounded-r bg-green-500 dark:bg-green-900">{{ provisionLvlName }}</span>
        </div>
        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">znaleziono: {{ count.filtered }}</span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">z {{ count.all }}</span>
        </div>
      </div>
    </div>

    <div id="product-list-content" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">

      <div id="product-list-table" class="flex flex-col gap-1">

        <div class="hidden lg:flex px-2">
          <div class="w-10 hidden xl:block self-end">id</div>
          <div class="w-1/3 2xl:w-1/4 hidden xl:flex">
            <div class="w-1/3">tow. ubezp</div>
            <div class="w-2/3">kategoria</div>
          </div>
          <div class="flex-grow min-w-0 self-end">produkt</div>
          <div class="w-1/5 flex items-end">
            <div class="w-1/2 text-right">oferta samodz.</div>
            <div class="w-1/2 text-right">oferta biura</div>
          </div>
        </div>

        <div class="flex flex-wrap xl:flex-nowrap px-2 py-1 border dark:border-gray-600 rounded shadow" v-for="product in filteredItems" :key="product.id">
          <div class="self-center flex-shrink-0 w-full xl:w-10 flex gap-x-2">
            <div class="block xl:hidden text-muted">id produktu</div><div>{{ product.product_id }}</div>
          </div>
          <div class="self-center flex-shrink-0 w-full xl:w-1/3 2xl:w-1/4 flex flex-wrap">
            <div class="flex-shrink-0 w-full sm:w-2/5 lg:w-1/3 flex gap-x-2">
              <div class="block lg:hidden text-muted">TU</div><div>{{ product.insurer_name }}</div>
            </div>
            <div class="flex-shrink-0 w-full sm:w-3/5 lg:w-2/3 flex gap-x-2">
              <div class="block lg:hidden text-muted">kategoria</div><div>{{ product.category_name }}</div>
            </div>
          </div>
          <div class="self-center flex-grow min-w-0 flex flex-wrap gap-x-2 overflow-x-hidden break-words">
            <div class="block lg:hidden text-muted">produkt</div><div class="text-bright">{{ product.name }}</div>
          </div>
          <div class="self-center flex-shrink-0 w-full lg:w-1/5 flex flex-wrap">
            <div class="flex-shrink-0 w-full sm:w-1/2 flex gap-x-2 justify-start lg:justify-end">
              <div class="block lg:hidden text-muted">oferta samodzielna</div><div class="text-bright">{{ product.provision_a.replace('.', ',') }} %</div>
            </div>
            <div class="flex-shrink-0 w-full sm:w-1/2 flex gap-x-2 justify-start lg:justify-end">
              <div class="block lg:hidden text-muted">oferta biura</div><div class="text-bright">{{ product.provision_b.replace('.', ',') }} %</div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import ProductService from '../../services/ProductService';
import SortList from '../SortList.vue';

export default {
  name: 'ProductListReadOnly',
  components: { SortList },
  mixins: [errorHandler],
  data() {
    return {
      products: [],
      sortedBy: {
        field: '',
        dir: 1,
      },
      sortFields: [
        { id: 'product_id', name: 'id produktu' },
        { id: 'insurer_name', name: 'tow. ubezp.' },
        { id: 'category_name', name: 'kategoria'},
        { id: 'name', name: 'nazwa produktu' },
        { id: 'provision_a', name: 'pr. samodz.' },
        { id: 'provision_b', name: 'pr. niesamodz.' },
      ],
      activeFilters: {
        search: '',
        category: '',
        insurer: '',
      },
      provisionLvlName: '',
    };
  },
  computed: {
    ...mapGetters('products', [
      'insurerList',
      'productCategoryList',
    ]),
    filteredItems() {
      if (Object.keys(this.activeFilters).length > 0) {
        const checkFilters = (item) => {
          let isMatch = true;
          Object.keys(this.activeFilters).forEach((key) => {
            if (this.activeFilters[key] !== '') {
              if (key === 'search') {
                const search = this.activeFilters[key].toLowerCase();
                if (!item.name.toLowerCase().includes(search)) {
                  isMatch = false;
                }
              } else if (key === 'category' && this.activeFilters[key] !== item.category_id) {
                isMatch = false;
              } else if (key === 'insurer' && this.activeFilters[key] !== item.insurer_id) {
                isMatch = false;
              }
            }
          });
          return isMatch;
        };
        return this.products.filter(checkFilters);
      }
      return this.products;
    },
    count() {
      return { filtered: this.filteredItems.length, all: this.products.length };
    },
  },
  created() {
    this.initList();
    if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    if (this.productCategoryList.length === 0) this.$store.dispatch('products/getProductCategoryList').catch(error => this.resolveError(error));
  },
  methods: {
    initList() {
      this.$store.dispatch('setLoadingData', true);
      ProductService.getProductListReadOnly()
        .then((response) => {
          this.products = response.data.result;
          this.provisionLvlName = response.data.provision_level.name;
          if (this.$route.query.sf !== undefined && ['product_id', 'insurer_name', 'category_name', 'name', 'provision_a', 'provision_b'].includes(this.$route.query.sf)) {
            this.sortedBy.field = this.$route.query.sf;
            if (this.$route.query.sd !== undefined) {
              this.sortedBy.dir = Number(this.$route.query.sd) === -1 ? -1 : 1;
            } else {
              this.sortedBy.dir = 1;
            }
          } else {
            this.sortedBy.field = 'insurer_name';
            this.sortedBy.dir = 1;
          }
          if (this.$route.query.search !== undefined) {
            this.activeFilters.search = decodeURI(this.$route.query.search);
          }
          if (this.$route.query.category !== undefined) {
            this.activeFilters.category = decodeURI(this.$route.query.category);
          }
          if (this.$route.query.insurer !== undefined) {
            this.activeFilters.insurer = decodeURI(this.$route.query.insurer);
          }
          this.sortItemList();
          this.setUrl();
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    sortItemsBy(object) {
      this.sortedBy.field = object.field;
      this.sortedBy.dir = object.dir;
      this.sortItemList();
      this.setUrl();
    },
    sortItemList() {
      const compareNumbers = (a, b) => this.sortedBy.dir * (a[this.sortedBy.field] - b[this.sortedBy.field]);
      const compareText = (a, b) => {
        const aName = a[this.sortedBy.field].toLowerCase();
        const bName = b[this.sortedBy.field].toLowerCase();
        let tmpDir = 0;
        if (aName < bName) {
          tmpDir = -1;
        } else if (aName > bName) {
          tmpDir = 1;
        }
        return this.sortedBy.dir * tmpDir;
      };
      if (['product_id', 'income', 'provision_a', 'provision_b'].includes(this.sortedBy.field)) {
        this.products.sort(compareNumbers);
      } else {
        this.products = this.products.sort(compareText);
      }
    },
    sortedIcon(field) {
      if (this.sortedBy.field === field) {
        return this.sortedBy.dir === 1 ? 'has-text-success fa-sort-amount-up' : 'has-text-success fa-sort-amount-down';
      }
      return 'fa-sort';
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.setUrl();
    },
    setUrl() {
      const query = {
        sf: this.sortedBy.field,
        sd: `${this.sortedBy.dir}`,
        search: encodeURI(this.activeFilters.search),
        category: encodeURI(this.activeFilters.category),
        insurer: encodeURI(this.activeFilters.insurer),
      };
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ name: 'ProductListReadOnly', query });
      }
    },
  },
};
</script>
